/// <reference types="web-bluetooth" />


enum MessageType {
    UNKNOWN,
    CLEAR,
    SET
}


enum KeyStrokeType {
    NONE,
    HOTKEY,
    MESSAGE
}


export class BLEMacropadConfig {
    public static readonly SERVICE_UUID = "46bcfb9a-9a9a-4730-a3e2-5adaee23ff31";
    public static readonly CHARACTERISTIC_UUID = "bd9ecc0e-4b79-44bf-9dc9-5729ecf1c225";

    private _device: BluetoothDevice | null;
    private _server: BluetoothRemoteGATTServer | null;
    private _service: BluetoothRemoteGATTService | null;
    private _characteristic: BluetoothRemoteGATTCharacteristic | null;
    private _isConnected: boolean;
    private _connectCallback: () => void;
    private _disconnectCallback: () => void;

    constructor() {
        this._device = null;
        this._server = null;
        this._service = null;
        this._characteristic = null;
        this._isConnected = false;

        this._connectCallback = () => { };
        this._disconnectCallback = () => { };
    }

    public async connect(connectCallback: () => void, disconnectCallback: () => void): Promise<void> {
        this._connectCallback = connectCallback;
        this._disconnectCallback = disconnectCallback;

        try {
            const device = await navigator.bluetooth.requestDevice({
                filters: [{
                    name: 'Macropad'
                }],
                optionalServices: [BLEMacropadConfig.SERVICE_UUID]
            });

            if (!device.gatt) {
                return Promise.reject(new Error("BLE Error: device.gatt is null."));
            }

            const server = await device.gatt.connect();
            const service = await server.getPrimaryService(BLEMacropadConfig.SERVICE_UUID);
            let characteristic = await service.getCharacteristic(BLEMacropadConfig.CHARACTERISTIC_UUID);
            characteristic = await characteristic.startNotifications();

            this._device = device;
            this._server = server;
            this._service = service;
            this._characteristic = characteristic;

            this._device.addEventListener('gattserverdisconnected', () => {
                this.onDisconnect()
            });
            this._characteristic.addEventListener('characteristicvaluechanged', this.onReceive);
            this.onConnect();

            return Promise.resolve();
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    public async disconnect(): Promise<void> {
        if (this._device && this._device.gatt) {
            this._device.gatt.disconnect();
        }

        return Promise.resolve();
    }

    public async send(message: string): Promise<void> {
        if (!this._characteristic) {
            return Promise.reject(new Error("BLE Error: characteristic is null."));
        }

        const uint8Array = new TextEncoder().encode(message);
        return this._characteristic.writeValue(uint8Array);
    }

    public async setMessage(keyIndex: number, message: string): Promise<void> {
        console.log("Save: " + keyIndex + " - " + message);

        if (!this._characteristic) {
            return Promise.reject(new Error("BLE Error: characteristic is null."));
        }

        const header = new Uint8Array([MessageType.SET, KeyStrokeType.MESSAGE, keyIndex]);
        const data = new TextEncoder().encode(message);

        const uint8Array = new Uint8Array(header.length + data.length);
        uint8Array.set(header);
        uint8Array.set(data, header.length);

        return this._characteristic.writeValue(uint8Array);
    }

    public async setHotkey(keyIndex: number, keys: Array<number>): Promise<void> {
        console.log("Save: " + keyIndex + " - " + keys);

        if (!this._characteristic) {
            return Promise.reject(new Error("BLE Error: characteristic is null."));
        }

        const header = new Uint8Array([MessageType.SET, KeyStrokeType.HOTKEY, keyIndex]);
        const data = Uint8Array.from(keys);

        const uint8Array = new Uint8Array(header.length + data.length);
        uint8Array.set(header);
        uint8Array.set(data, header.length);

        return this._characteristic.writeValue(uint8Array);
    }

    public get isConnected(): boolean {
        return this._isConnected;
    }

    private onConnect(): void {
        this._isConnected = true;

        console.log("==================");
        console.log("Macropad Connected");
        console.log("------------------");
        console.log(this._device);
        console.log(this._server);
        console.log(this._service);
        console.log(this._characteristic);
        console.log("==================");

        this._connectCallback();
    }

    private onDisconnect(): void {
        this._isConnected = false;

        this._device = null;
        this._server = null;
        this._service = null;
        this._characteristic = null;

        console.log("=====================");
        console.log("Macropad Disconnected");
        console.log("=====================");

        this._disconnectCallback();
    }

    private onReceive(e: Event): void {
        //@ts-ignore
        const uint8Array = e.target.value;
        const str = new TextDecoder().decode(uint8Array);

        console.log("=================");
        console.log("Macropad Received")
        console.log("-----------------");
        console.log(str);
        console.log("=================");
    }

}