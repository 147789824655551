
import Vue from "vue";

export default Vue.extend({
  name: "Main",

  props: {
    keyIndex: {
      type: Number,
      required: true,
    },
  },

  data: function (): any {
    return {
      keySize: "123px",
      keyColor: "transparent",
    };
  },

  computed: {
    hidden: function (): boolean {
      return this.keyIndex < 0;
    },
  },
});
