
import Vue from "vue";
import { BLEMacropadConfig } from "../logic/BLEMacropadConfig";
import { USBMacropadConfig } from "../logic/USBMacropadConfig";
import { Keymap } from "../logic/Keymap";
import Key from "../components/Key.vue";

export default Vue.extend({
  name: "Main",

  components: {
    Key,
  },

  data: function (): any {
    return {
      activeMacropadConfig: null,
      usbMacropadConfig: USBMacropadConfig,
      bleMacropadConfig: BLEMacropadConfig,
      usbIsConnected: false,
      bleIsConnected: false,
      keystroke: Array,
      keystrokeString: String,
      activeKey: undefined,
      mode: undefined,
      isLoading: false,
      hasError: false,
      errorMessage: "",
    };
  },

  mounted: function (): void {
    this.activeMacropadConfig = null;
    this.usbMacropadConfig = new USBMacropadConfig();
    this.bleMacropadConfig = new BLEMacropadConfig();
    this.clearKeystroke();
  },

  computed: {
    isAnyConnected: function (): boolean {
      return this.usbIsConnected || this.bleIsConnected;
    },

    isDisabled: function (): boolean {
      return !this.isAnyConnected;
    },

    isKeystrokeHidden: function (): boolean {
      return this.isDisabled || this.activeKey == undefined;
    },

    isKeystrokeReadonly: function (): boolean {
      return this.mode == 0;
    },

    connectButtonText: function (): string {
      return this.isConnected ? "disconnect" : "connect";
    },
  },

  methods: {
    clearKeystroke: function (): void {
      this.mode;
      this.keystroke = new Array<number>();
      this.keystrokeString = "";
    },

    enterHotkeyMode: function (): void {
      this.clearKeystroke();
      document.addEventListener("keyup", this.onKeystroke);
    },

    leaveHotkeyMode: function (): void {
      this.clearKeystroke();
      document.removeEventListener("keyup", this.onKeystroke);
    },

    enterMessageMode: function (): void {
      this.clearKeystroke();
    },

    leaveMessageMode: function (): void {
      this.clearKeystroke();
    },

    saveKeystroke: function (): void {
      this.isLoading = true;

      const keyIndex = this.activeKey;
      const stroke = this.keystroke;
      const strokeString = this.keystrokeString;

      setTimeout(() => {
        if (this.mode == 0) {
          this.activeMacropadConfig
            .setHotkey(keyIndex, stroke)
            .then(() => {
              this.isLoading = false;
            })
            .catch((e: Error) => {
              console.error(e);
              this.isLoading = false;
            });
        } else {
          this.activeMacropadConfig
            .setMessage(keyIndex, strokeString)
            .then(() => {
              this.isLoading = false;
            })
            .catch((e: Error) => {
              console.error(e);
              this.isLoading = false;
            });
        }
      }, 0);
    },

    showError(message: string): void {
      this.errorMessage = message;
      this.hasError = true;
    },

    onKeyChange: function (value: any): void {
      if (this.activeKey != undefined) {
        this.saveKeystroke();
      }

      this.activeKey = value;
      if (this.activeKey != undefined) {
        this.mode = 0;
        this.onModeChange(this.mode);
      }
    },

    onModeChange: function (value: any): void {
      if (value == 0) {
        this.leaveMessageMode();
        this.enterHotkeyMode();
      } else {
        this.leaveHotkeyMode();
        this.enterMessageMode();
      }
    },

    onBLEConnectClick: function (): void {
      this.isLoading = true;

      setTimeout(() => {
        this.bleMacropadConfig
          .connect(
            () => {
              this.onConnect(this.bleMacropadConfig);
            },
            () => {
              this.onDisconnect();
            }
          )
          .catch((e: Error) => {
            this.isLoading = false;

            //@ts-ignore
            if (e.code != 8) {
              this.showError(e.message);
            }
          });
      }, 0);
    },

    onUSBConnectClick: function (): void {
      this.isLoading = true;

      setTimeout(() => {
        this.usbMacropadConfig
          .connect(
            () => {
              this.onConnect(this.usbMacropadConfig);
            },
            () => {
              this.onDisconnect();
            }
          )
          .catch((e: Error) => {
            this.isLoading = false;

            //@ts-ignore
            if (e.code != 8) {
              this.showError(e.message);
            }
          });
      }, 0);
    },

    onDisconnectClick: function (): void {
      if (this.isAnyConnected && this.activeMacropadConfig != null) {
        this.activeMacropadConfig.disconnect();
        this.activeMacropadConfig = null;
      }
    },

    onConnect: function (config: any): void {
      this.bleIsConnected = true;
      this.isLoading = false;
      this.activeMacropadConfig = config;
    },

    onDisconnect: function (): void {
      this.bleIsConnected = false;
      this.isLoading = false;
      this.activeMacropadConfig = null;
    },

    onKeystroke: function (e: KeyboardEvent): void {
      e.preventDefault();
      e.stopPropagation();

      const ascii = e.key.charCodeAt(0);
      const keyName = e.code;
      const keyCode = Keymap.getCode(keyName, ascii);

      if (this.keystroke.includes(keyCode)) {
        return;
      }

      if (this.keystrokeString.length > 0) {
        this.keystrokeString += " + ";
      }

      this.keystrokeString += keyName;
      this.keystroke.push(keyCode);
    },
  },
});
