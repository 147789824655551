/// <reference types="w3c-web-serial" />


enum MessageType {
    UNKNOWN,
    CLEAR,
    SET
}


enum KeyStrokeType {
    NONE,
    HOTKEY,
    MESSAGE
}


export class USBMacropadConfig {

    private _port: SerialPort | null;
    private _reader: ReadableStreamDefaultReader<Uint8Array> | null;
    private _writer: WritableStreamDefaultWriter<Uint8Array> | null;
    private _isConnected: boolean;

    private _connectCallback: () => void;
    private _disconnectCallback: () => void;


    constructor() {
        this._port = null;
        this._reader = null;
        this._writer = null;
        this._isConnected = false;

        this._connectCallback = () => { };
        this._disconnectCallback = () => { };
    }


    public async connect(connectCallback: () => void, disconnectCallback: () => void): Promise<void> {
        this._connectCallback = connectCallback;
        this._disconnectCallback = disconnectCallback;

        try {
            const port = await navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });

            if (port.readable) {
                this._reader = port.readable.getReader();
            }

            if (port.writable) {
                this._writer = port.writable.getWriter();
            }

            const signals = await port.getSignals();
            console.log(signals);

            port.addEventListener("disconnect", () => {
                this.onDisconnect();
            });

            this._port = port;

            this.onConnect();
            return Promise.resolve();
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }


    public async disconnect(): Promise<void> {
        if (this._reader) {
            await this._reader.cancel();
            this._reader.releaseLock();
            this._reader = null;
        }

        if (this._writer) {
            this._writer.releaseLock();
            this._writer = null;
        }

        if (this._port) {
            await this._port.close();
            this._port = null;
        }

        this.onDisconnect();
        return Promise.resolve();
    }


    public async setMessage(keyIndex: number, message: string): Promise<void> {
        console.log("Save: " + keyIndex + " - " + message);

        if (!this._writer) {
            return Promise.reject(new Error("USB Error: writer is null."));
        }

        const header = new Uint8Array([MessageType.SET, KeyStrokeType.MESSAGE, keyIndex]);
        const data = new TextEncoder().encode(message);

        const uint8Array = new Uint8Array(header.length + data.length);
        uint8Array.set(header);
        uint8Array.set(data, header.length);

        return this._writer.write(uint8Array);
    }


    public async setHotkey(keyIndex: number, keys: Array<number>): Promise<void> {
        console.log("Save: " + keyIndex + " - " + keys);

        if (!this._writer) {
            return Promise.reject(new Error("USB Error: writer is null."));
        }

        const header = new Uint8Array([MessageType.SET, KeyStrokeType.HOTKEY, keyIndex]);
        const data = Uint8Array.from(keys);

        const uint8Array = new Uint8Array(header.length + data.length);
        uint8Array.set(header);
        uint8Array.set(data, header.length);

        return this._writer.write(uint8Array);
    }


    public get isConnected(): boolean {
        return this._isConnected;
    }


    private onConnect(): void {
        this._isConnected = true;

        console.log("==================");
        console.log("Macropad Connected");
        console.log("==================");

        this._connectCallback();
    }

    private onDisconnect(): void {
        this._isConnected = false;

        console.log("=====================");
        console.log("Macropad Disconnected");
        console.log("=====================");

        this._disconnectCallback();
    }

    private onReceive(e: Event): void {
        console.log("=================");
        console.log("Macropad Received")
        console.log("=================");
    }

}