export class Keymap {
    public static readonly _map: any = {
        "ControlLeft": 0x80,
        "ShiftLeft": 0x81,
        "AltLeft": 0x82,
        "MetaLeft": 0x83,
        "ControlRight": 0x84,
        "ShiftRight": 0x85,
        "AltRight": 0x86,
        "MetaRight": 0x87,

        "ArrowUp": 0xDA,
        "ArrowDown": 0xD9,
        "ArrowLeft": 0xD8,
        "ArrowRight": 0xD7,

        "Backspace": 0xB2,
        "Tab": 0xB3,
        "Enter": 0xB0,
        "Escape": 0xB1,
        "Insert": 0xD1,
        "PrintScreen": 0xCE,
        "Delete": 0xD4,
        "PageUp": 0xD3,
        "PageDown": 0xD6,
        "Home": 0xD2,
        "End": 0xD5,
        "CapsLock": 0xC1,

        "F1": 0xC2,
        "F2": 0xC3,
        "F3": 0xC4,
        "F4": 0xC5,
        "F5": 0xC6,
        "F6": 0xC7,
        "F7": 0xC8,
        "F8": 0xC9,
        "F9": 0xCA,
        "F10": 0xCB,
        "F11": 0xCC,
        "F12": 0xCD,
        "F13": 0xF0,
        "F14": 0xF1,
        "F15": 0xF2,
        "F16": 0xF3,
        "F17": 0xF4,
        "F18": 0xF5,
        "F19": 0xF6,
        "F20": 0xF7,
        "F21": 0xF8,
        "F22": 0xF9,
        "F23": 0xFA,
        "F24": 0xFB,

        "Numpad0": 0xEA,
        "Numpad1": 0xE1,
        "Numpad2": 0xE2,
        "Numpad3": 0xE3,
        "Numpad4": 0xE4,
        "Numpad5": 0xE5,
        "Numpad6": 0xE6,
        "Numpad7": 0xE7,
        "Numpad8": 0xE8,
        "Numpad9": 0xE9,

        "NumpadDivide": 0xDC,
        "NumpadMultiply": 0xDD,
        "NumpadSubtract": 0xDE,
        "NumpadAdd": 0xDF,
        "NumpadEnter": 0xE0,
        "NumpadDecimal": 0xEB,
    }

    public static getCode(name: string, keyCode: number): number {
        if (name in Keymap._map) {
            return Keymap._map[name];
        }
        else {
            return keyCode;
        }
    }
}